import { defineStore } from "pinia"
import { computed } from "vue"
import i18n from "@/i18n"

export type Locale = "en" | "de" | "es" | "fr"
export const DEFAULT_LOCALE = "en" as Locale
export const SUPPORTED_LOCALES = ["en", "de", "es", "fr"]

export const useLanguageStore = defineStore("useLanguageStore", () => {
  // Gets the current locale from i18n
  const currentLocale = computed(() => i18n.global.locale.value as Locale)

  // Checks if the provided locale is supported
  function isLocaleSupported(locale: string): boolean {
    return SUPPORTED_LOCALES.includes(locale)
  }

  // Determines the user's preferred locale based on browser settings or default
  function getUserLocale(): Locale {
    const locale = window.navigator.language ?? DEFAULT_LOCALE
    return locale.split("-")[0] as Locale
  }

  // Determines the best default locale for the user
  function guessDefaultLocale(): Locale {
    const userPreferredLocale = getUserLocale()
    return isLocaleSupported(userPreferredLocale) ? userPreferredLocale : DEFAULT_LOCALE
  }

  // Sets the current locale in i18n
  function setCurrentLocale(newLocale: Locale) {
    if (isLocaleSupported(newLocale)) {
      i18n.global.locale.value = newLocale
      document.querySelector("html")!.setAttribute("lang", newLocale)
    }
  }

  // Switches the application's language
  function switchLanguage(newLocale: Locale) {
    setCurrentLocale(newLocale)
  }

  return {
    isLocaleSupported,
    getUserLocale,
    guessDefaultLocale,
    currentLocale,
    setCurrentLocale,
    switchLanguage
  }
})
