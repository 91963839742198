import { createI18n } from "vue-i18n"

import en from "@/i18n/locales/en.json"
import de from "@/i18n/locales/de.json"
import es from "@/i18n/locales/es.json"
import fr from "@/i18n/locales/fr.json"
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from "@/store/useLanguageStore"

const i18n = createI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  legacy: false,
  availableLocales: SUPPORTED_LOCALES,
  messages: {
    en,
    de,
    es,
    fr
  }
})

export default i18n
