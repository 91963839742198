{
  "topMenu": {
    "dihTitle": "Data Intelligence Hub",
    "dihSubTitle": "Extraire la valeur des données en toute sécurité",
    "logout": "Se déconnecter"
  },
  "userLogoutAlert": {
    "title": "Êtes-vous sûr de vouloir vous déconnecter?",
    "back": "Retour",
    "logout": "Se déconnecter"
  },
  "sideMenu": {
    "home": "Home",
    "collapse": "Collapse sidebar",
    "help": "Help",
    "prepare": "Prepare",
    "model": "Data model",
    "mapping": "Data mapping",
    "converter": "Data converter",
    "provide": "Provide",
    "publishData": "Provide data",
    "policyPublicationTemplate": "Policy publication template",
    "outgoingDataOffer": "Asset upload history",
    "consume": "Consume",
    "searchDataOffers": "Search data offers",
    "negotiatedDataOffers": "Negotiated data offers",
    "downloadHistory": "Asset download history",
    "govern": "Govern",
    "myPolicies": "My policies",
    "createPolicies": "Create Policies",
    "policyTemplate": "Policy Templates",
    "manageAndConfig": "Configure",
    "connections": "Manage connectors",
    "jobs": "Data exchange logs",
    "monitoring": "Monitor",
    "licences": "Licences",
    "providerContracts": "Subscribed data agreements",
    "consumerContracts": "Negotiated agreements"
  },
  "dropdown": {
    "selectAll": "Sélectionner tout",
    "noOption": "Aucune option disponible",
    "noSuchOption": "Cette option n'existe pas ..."
  },
  "common": {
    "start": "Commencer",
    "firstName": "Prénom",
    "lastName": "Nom de famille",
    "email": "Adresse e-mail",
    "phoneNumber": "Numéro de téléphone",
    "contactPerson": "Personne de contact",
    "welcome": "Accueillir",
    "street": "Rue",
    "city": "Ville",
    "country": "Pays",
    "postCode": "Code postal",
    "number": "Nombre",
    "next": "Suivant",
    "prev": "Précédent",
    "alreadyAccount": "Vous avez déjà un compte?",
    "login": "Se connecter",
    "loginNow": "Se connecter maintenant",
    "register": "S'inscrire",
    "home": "Page d'accueil",
    "submit": "Envoyer",
    "attention": "Attention",
    "desc": "Description",
    "website": "Site web",
    "contact": "Contact",
    "details": "Détails",
    "plan": "Plan",
    "listView": "Vue liste",
    "gridView": "Vue grille",
    "firstPage": "À la première page",
    "previousPage": "À la page précédente",
    "nextPage": "À la page suivante",
    "lastPage": "À la dernière page",
    "download": "Télécharger",
    "delete": "Supprimer",
    "edit": "Modifier",
    "information": "Information",
    "organization": "Organisation",
    "cancel": "Annuler",
    "save": "Sauvegarder",
    "upload": "Télécharger",
    "link": "Lien",
    "view": "Voir",
    "search": "Rechercher",
    "searchTerm": "Recherche",
    "selectOption": "Choisir une option",
    "sortBy": "Trier par",
    "dataspace": "Espace de données",
    "select": "Sélectionner",
    "date": "Date",
    "title": "Titre",
    "back": "Back",
    "useCases": "Use cases",
    "done": "Done",
    "enterValue": "Enter a value",
    "selectValue": "Select a value",
    "selectDate": "Select a date",
    "refresh": "Refresh",
    "searchByName": "Search by name"
  },
  "genericError": "Oh, quelque chose a mal tourné ! Veuillez réessayer.",
  "validation": {
    "fieldRequired": "Champ requis.",
    "fieldNumeric": "Le champ peut ne contenir que des nombres.",
    "fieldAlpha": "Le champ ne peut contenir que des lettres, _ et -.",
    "fieldAlphanumeric": "Le champ ne peut contenir que des lettres, des numéros, _ et -.",
    "fieldNumericDash": "Le champ peut ne contenir que des nombres et -.",
    "fieldHouseNumber": "Le champ {field} ne peut contenir que des chiffres, des lettres et les caractères - et /.",
    "fieldPhoneNumber": "Le champ peut ne contenir que des nombres et -. Il doit commencer par + ou 00.",
    "fieldAlphaDash": "Le champ peut ne contenir que des lettres et -.",
    "exceptHTMLTagBrackets": "Le champ ne doit pas contenir les caractères suivants: ^ <>",
    "isMaxLength": "dépasse la longueur maximale de 10 caractères.",
    "emailFormat": "L'adresse e-mail a un mauvais format",
    "fileSizeError": "La taille du fichier est plus grande que {size} MB.",
    "csvFileSizeError": "Ce fichier est trop gros. Vous pouvez télécharger un fichier jusqu'à {size} Mo.",
    "csvFileExtensionError": "Seuls les fichiers avec l'extension csv sont autorisés.",
    "csvFileIsEmptyError": "Ce fichier est vide. Vous devez télécharger un fichier d'au moins {size} Ko.",
    "csvFileFormatError": "Le fichier ne correspond pas au format attendu. Veuillez utiliser le modèle fourni et réessayer.",
    "uploadFails": "échec du téléchargement",
    "fileExtensionError": "L'extension de fichier ne convient pas",
    "fileIsMalwareInfectedError": "Ce fichier semble contenir des logiciels malveillants. Pour des raisons de sécurité, ce fichier ne peut pas être téléchargé. Veuillez vérifier à nouveau votre fichier",
    "customValidation": "Le {field} a le mauvais format.",
    "selectCountryFirst": "Veuillez d'abord sélectionner le champ du nom du pays.",
    "validationPostCodeCaseDE": "Le champ a le mauvais format. Il doit contenir 5 chiffres, par exemple, 53113",
    "validationAlphanumericCase": "Le champ peut ne contenir que des caractères alphanumériques, des tirets et des soulignements. La valeur doit commencer par un caractère alphanumérique.",
    "validationNumericDashCase": "Le champ peut ne contenir que des nombres et -.",
    "url": "Veuillez saisir une URL valide",
    "password": {
      "passwordStrength": "Fiabilité du mot de passe",
      "weak": "Faible",
      "average": "Moyenne",
      "strong": "Fort",
      "passwordValidationMoreChar": "{count} ou plus de caractères",
      "passwordUpperAndLowerChar": "Caractères supérieurs et minuscules",
      "passwordAtLeastNumber": "Au moins un nombre",
      "passwordAtLeastSpecialChar": "Au moins un personnage spécial (sauf <> & \"` [])",
      "passwordDiffFromPrev": "Diffère de vos mots de passe précédents"
    }
  },
  "footer": {
    "tagLine": "Passons des performances plus élevées",
    "imprint": "Imprimer",
    "imprintShort": "Mentions lég.",
    "imprintLink": "https://dih.telekom.com/en/imprint",
    "contact": "Contact",
    "contactLink": "https://dih.telekom.com/en/contact",
    "termsAndConditions": "Termes et conditions",
    "privacy": "Politique de confidentialité",
    "privacyShort": "Politique confident.",
    "telekom": "T-Systems International GmbH. Tous droits réservés.",
    "telekomShort": "T-Systems International GmbH",
    "note": "Telekom Data Intelligence Hub - pour les clients d'entreprise uniquement",
    "brandedNote": "Propulsé par Telekom Data Intelligence Hub",
    "help": "Aide",
    "cookieSettings": "Cookies",
    "cookieSettingsShort": "Cookies",
    "life": "Life is for sharing"
  },
  "cookieSettings": {
    "collapsedTitle": "Paramètres de confidentialité",
    "collapsedText": "Ce site Web utilise des cookies et des technologies similaires. Ce sont de petits fichiers texte qui sont stockés et lus sur votre ordinateur. En cliquant sur '{0}', vous acceptez le traitement, la création de profils utilisateur individuels sur les sites Web et les partenaires, ainsi que la transmission de vos données à des tiers, dont certains traitent vos données dans des pays en dehors de l'Union européenne (RGPD Art. 49). Les détails se trouvent dans la section 2 de la {1}. Les données sont utilisées à des fins d'analyse, de reciblage et d'affichage de contenu et de publicité personnalisés sur les sites Telekom et les sites tiers. De plus amples informations, y compris des informations sur le traitement des données par des fournisseurs tiers et la possibilité de révocation, se trouvent dans les paramètres et dans notre politique de confidentialité. Ici, vous pouvez {2} uniquement avec les outils nécessaires.",
    "extendedTitle": "Paramètres de confidentialité",
    "extendedText": "Afin de vous offrir une expérience optimale sur notre site Web, nous utilisons des cookies. Ceux-ci incluent des cookies pour le fonctionnement et l'optimisation du site, ainsi que pour des services tels que le chat texte ou vidéo et la publicité basée sur votre comportement en ligne. Cela nous permet, par exemple, de détecter si vous visitez nos pages à plusieurs reprises à partir du même appareil. Nous aimerions vous donner le choix des cookies que vous autorisez :",
    "policyLinkHref": "https://dih.telekom.com/en/privacy-policy",
    "policyLinkCookieHref": "https://dih.telekom.com/en/privacy-policy",
    "policyLinkTextShort": "informations de confidentialité",
    "policyLinkText": "Informations de confidentialité",
    "rejectLinkText": "continuer",
    "acceptButton": "Accepter tout",
    "saveButton": "Enregistrer les paramètres",
    "settingsButton": "Modifier les paramètres",
    "showMore": "Montrer plus",
    "showLess": "Montrer moins",
    "categoryTitleEssential": "Cookies requis",
    "categoryTitleAnalytics": "Cookies analytiques",
    "categoryTitleMarketing": "Cookies marketing",
    "categoryTitleThirdParty": "Services d'autres sociétés (fournisseurs de tiers indépendants)",
    "categoryTextEssential": "Ces cookies sont nécessaires pour vous permettre de naviguer sur les sites Web et d'utiliser des fonctions essentielles. Ils prennent en charge des fonctions de base, telles que le traitement des commandes dans la boutique en ligne et l'accès aux zones sécurisées du site Web. Ils servent également à effectuer une analyse anonyme des modèles d'utilisation, que nous utilisons pour développer et améliorer continuellement nos pages Web.",
    "categoryTextAnalytics": "Ces cookies nous aident à améliorer notre compréhension du comportement des utilisateurs. Les cookies d'analyse permettent la compilation de données d'utilisation et d'identification par le fournisseur d'origine ou par des fournisseurs tiers en profils d'utilisation pseudonymes. Nous utilisons des cookies d'analyse, par exemple, pour déterminer le nombre de visiteurs individuels sur une page Web ou un service, pour collecter des données statistiques sur les performances de nos produits et pour analyser les modèles d'utilisation et les interactions des visiteurs sur la base d'informations anonymes et pseudonymes. Ces informations ne peuvent pas être reliées à une personne.",
    "categoryTextMarketing": "Ces cookies et technologies similaires sont utilisés pour permettre l'affichage de contenu marketing personnalisé et donc pertinent. Les cookies marketing sont utilisés pour afficher un contenu publicitaire intéressant et mesurer l'efficacité de nos campagnes. Cela se produit non seulement sur les sites Web Telekom, mais aussi sur d'autres sites de partenaires publicitaires (fournisseurs tiers). Ceci est également appelé reciblage. Il est utilisé pour créer du contenu pseudonyme ou des profils publicitaires, pour placer la publicité pertinente sur d'autres sites Web et pour dériver des informations sur les groupes cibles qui ont visionné les annonces et le contenu. Ces informations ne peuvent pas être retracées à une personne. Les outils de marketing et de reciblage nous aident à vous servir de contenu publicitaire potentiellement pertinent pour vous. En supprimant les cookies marketing, vous verrez toujours la même quantité de publicité, mais cela peut être moins pertinent pour vous.",
    "categoryTextThirdParty": "Les sites Internet de Telekom comportent des liens vers des fournisseurs de services tiers, qui proposent leurs services sous leur propre responsabilité. Lorsque vous consultez un site Internet de Telekom, des cookies ou des technologies similaires enregistrent des données et les transmettent à des tiers, en partie pour répondre à des objectifs propres à Telekom. L'étendue, l'objectif et la base juridique du traitement ultérieur effectué à des fins propres à la tierce partie sont indiqués dans les informations sur la confidentialité des données de ladite tierce partie. Des informations relatives aux fournisseurs tiers indépendants sont disponibles dans les informations sur la confidentialité des données."
  },
  "messages": {
    "noDataAvailable": "Pas de données disponibles",
    "noMatchFound": "Pas de résultat trouvé",
    "maximumFileSize": "Taille maximale du fichier",
    "suitableFileFormats": "Formats de fichiers appropriés",
    "loading": "Chargement...",
    "dragDropText": "ou faites-le glisser et déposez-le ici"
  },
  "pageNotFound": {
    "title": "Oops ! Il semble que la page que vous cherchez n'existe pas encore.",
    "subtitle": "Découvrez nos autres ressources en attendant, qui pourraient vous être utiles !"
  },
  "helpResources": {
    "needHelp": "Besoin d'aide ?",
    "developerDoc": "Integrator documentation",
    "developerDocDesc": "Get API documentation to get started",
    "example": "Example",
    "tutorials": "Tutorials",
    "exampleDescription": "Find use cases and demos relevant to your Industrial sector",
    "FAQ": "Foire aux questions",
    "FAQDescription": "Trouvez plus de réponses courantes, de la facturation à la confidentialité",
    "browse": "Parcourir",
    "demos": "Démos",
    "demosDescription": "Trouvez des cas d'utilisation et des démos liés à votre secteur industriel",
    "glossary": "Glossaire",
    "glossaryDescription": "Trouvez les termes et définitions liés à la DIH, IDS, Catena-X, GAIA-X et plus encore.",
    "contactUs": "This feature is not supported in your current subscription. Please contact us to upgrade."
  },
  "feedbackCard": {
    "giveUsFeedback": "Need more help?",
    "contactUs": "Contactez-nous"
  },
  "error": {
    "pageNotFound": "Page introuvable (404)"
  },
  "helpWidget": {
    "askSupport": "Demander de l’aide",
    "chatboxTitle": "Chat d’aide",
    "chatboxSubtitle": "Prise en charge de Telekom Data Intelligence Hub",
    "defaultMessage": {
      "body": "Bonjour, bienvenue sur le canal d’assistance Telekom Data Intelligence Hub. Veuillez sélectionner une option ci-dessous."
    },
    "whatCanWeHelpWith": "En quoi pouvons-nous vous aider aujourd’hui ?",
    "suggestedAnswers": {
      "knowledgeBase": "Base de connaissances, documentation et FAQ",
      "contactSupport": "Contacter l'assistance",
      "contactSales": "Contacter le service commercial"
    }
  },
  "provider": {
    "provider": "Provider",
    "contracts": {
      "description": "This is an overview of the data exchange contracts your organization has with other Catena-X members to receive & consume data from them. ",
      "howItWorks": {
        "description": "Here you can find all your subscribed or negotiated data agreements across various dataspaces. You can filter the results for a particular connector or simply search with a contract agreement ID or Asset ID. You can also sort the list to view data agreements based on your requirements. Further, you can select the data offers and export them locally if you wish to.",
        "subtitle": "Want to know more about how data offer negotiation and consumption work?"
      },
      "details": {
        "title": "Contract Details",
        "description": "Description",
        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        "agreementDetails": "Agreement Details",
        "assetDetails": "Asset Details",
        "usagePolicy": "Usage Policy"
      }
    }
  },
  "home": {
    "title": "Connect & Integrate"
  },
  "gettingStarted": {
    "title": "Need help getting started?",
    "openWizard": "Open Wizard",
    "yourDataOffering": "Your data offering",
    "yourAssets": "Your assets",
    "yourPolicies": "Your Policies",
    "preconfiguredCatalogue": "Preconfigured catalogue",
    "contractAgreements": "Contract Agreements",
    "incomingAgreements": "Incoming agreements",
    "outgoingAgreements": "Outgoing agreements",
    "connectors": "Your connectors"
  },
  "yourConnection": {
    "title": "Your connections",
    "viewAllConnection": "View all connectors",
    "help": "Help",
    "documentation": "Get to know your Connect & Integrate",
    "gettingStarted": "Getting started guide",
    "noUseCases": "No use cases available"
  },
  "wizardSelectionPopup": {
    "whatToLike": "What would you like to do today?",
    "prepare": "Prepare",
    "govern": "Govern",
    "provide": "Provide",
    "consume": "Consume",
    "howToProvide": "How would you like to provide data today?",
    "manual": "Manual",
    "automated": "Automated via an API",
    "selectUseCases": "Select your use case(s)",
    "selectSingleUseCase": "Select your use case",
    "selectDigitalTwin": "Select your digital twin type",
    "back": "Back",
    "next": "Next"
  },
  "createOffer": {
    "title": { "standard": "Create data offer", "pcf": "Create PCF data offer" },
    "publish": "Publish",
    "wizard": {
      "provisionAsset": "Provision asset",
      "dataUpload": "Data upload",
      "attachPolicy": "Attach policy",
      "publishDataOffer": "Publish data offer"
    },
    "provisionAsset": {
      "howToSelectSubmodel": "For which submodel would you like to create a data offer?",
      "selectSubmodel": "Select submodel",
      "selectUsecase": "Select a Use Case",
      "selectDigitalTwinType": "Select a digital twin type",
      "whichTypeToSelect": "Which type should I select?",
      "generalInformation": "General Information",
      "name": "Name",
      "id": "ID",
      "description": "Description",
      "contentType": "Content Type",
      "version": "Version",
      "keywords": "Keywords",
      "language": "Language",
      "location": {
        "title": "Location and addressing",
        "text": "Please enter the details of your asset"
      },
      "username": "Username",
      "password": "Password",
      "assetUrl": "Asset URL",
      "assetUrlPlaceHolder": "Please insert your asset URL",
      "clientId": "Client ID",
      "clientSecret": "Client Secret",
      "tokenUrl": "Token Url",
      "tokenUrlPlaceHolder": "Please insert your token URL",
      "provideAssets": "Provide asset",
      "selectConnectorLabel": "Select which connector(s) you would like to share your data with",
      "selectConnectorPlaceholder": "Select a connector",
      "selectRestApiType": "Select REST-API type",
      "selectedAuthMethod": "Authentication method"
    },
    "dataUpload": {
      "selectedDataModel": "Select your data upload",
      "csvUpload": {
        "title": "CSV file upload",
        "uploadFile": "Upload File",
        "uploadFileDescription": "Prepare and upload the file you would like to exchange. You can download the sample file to help you get started with the  format required for Catena-X.",
        "uploadFileDescriptionNote1": "Download the template file from the link below",
        "uploadFileDescriptionNote2": "Fill in the provided file with details of your partners",
        "uploadFileDescriptionNote3": "Upload the file in the provided area",
        "partnerValidationNote": "If you would like to learn more about partner validation, please",
        "clickHere": "click here",
        "downloadTemplate": "Download Template",
        "downloadSampleCsv": "Download Sample csv"
      },
      "jsonUpload": {
        "title": "json upload"
      },
      "tableUpload": {
        "title": "Table upload",
        "add": "Add"
      },
      "fileUpload": {
        "title": "File upload",
        "description": "csv, json"
      },
      "objectStorage": {
        "title": "Object storage",
        "description": "AWS, Azure, OTC or GCP"
      },
      "sftp": {
        "title": "SFTP Server",
        "description": "transfer via SFTP"
      },
      "apiUpload": {
        "title": "REST API",
        "description": "transfer via API",
        "authAndIdentiTitle": "Authentication and identification",
        "authAndIdentiDesc": "Please enter authentication details of your destination system",
        "selectApiType": "Select REST-API type",
        "locationAndAddrTitle": "Location and addressing",
        "locationAndAddrDesc": "Please enter the details of your asset",
        "assetUrl": "Asset URL",
        "username": "Username",
        "password": "Password",
        "clientId": "Client ID",
        "clientSecret": "Client Secret"
      }
    },
    "fileUpload": {
      "title": "Drag and drop your file here",
      "btnText": "Upload"
    },
    "attachPolicy": {
      "title": "Attach a policy to this data offer",
      "createPolicy": "Create a new policy",
      "bpnFieldTitle": "With whom do you want to exchange your data?",
      "howItWork": {
        "title": "How does it work?",
        "description": "We pre-configure the policies based on the Catena-X Use Case framework agreement. Access to your data will be allowed only for the provided Business Partner Numbers.",
        "knowMore": "Want to know more about the policies?",
        "documentation": "Documentation"
      },
      "accessPolicyTableText": "Attach an access policy to this data offer",
      "usagePolicyTableText": " Attach a usage policy to this data offer",
      "non-cx": {
        "howItWork": {
          "title": "How does it work?",
          "description": "Discover the data offer catalog of other Catena-X member companies. You can search, select and subscribe to a data offer. You can search for data offers using the company name or with the Manufacturer Part ID or with a combination of two."
        }
      }
    },
    "publishDataOffer": {
      "dataOfferDetails": "Data offer details",
      "generalInformation": "General Information",
      "attribute": "Attribute",
      "value": "Value",
      "knowMore": "Know more about your data offer",
      "attachedDataAssets": "Attached items",
      "attachedPolicies": "Attached policies",
      "allowPolicyPublish": "I allow policy publication",
      "publish": "Publish",
      "verifiedLegalEntity": "Verified legal entity by",
      "dataOfferCreatedBy": "Data offer created by: ",
      "contactOrgAdmin": "Contact Organization Admin",
      "help": "Help",
      "attachedDataAsset": "Attached data asset",
      "attachedAccessPolicy": "Attached access policy",
      "attachedUsagePolicy": "Attached usage policy",
      "publishedConnectors": "Connectors to which this offer will be published",
      "knowMoreConnector": "Know more about your connectors",
      "knowMorePolicies": "Know more about your policies",
      "knowMoreDataAssets": "Know more about your data assets",
      "knowMoreDataOffers": "Know more about your data offer",
      "publishCheckboxLabel": "I, on behalf of my organization, agree to publish the data offer and assets in the dataspace. I confirm the usage of associated policies to govern the dataspace and associated policies and I will follow the framework of the dataspace for any operation on the offer and assets once published."
    },
    "result": {
      "success": {
        "title": "Successful request.",
        "description": "Asset successfully published. Job id is {id}"
      }
    },
    "success": {
      "title": "Your data offer is now published",
      "description": "Your data offer is now published, click the following for the next steps",
      "createOffer": "Create another data offer",
      "createOfferButton": "Create",
      "goToHome": "Go to home",
      "goToHomeButton": "Go to home",
      "uploadHistory": "Check your upload history"
    }
  },
  "policy": {
    "newPolicy": "New policy",
    "showDeletedPolicies": "Show deleted policies",
    "hideDeletedPolicies": "Show active policies",
    "restoreAsset": "Restore asset",
    "publishDeletion": "Publish deletion",
    "create": {
      "title": "Create new policy",
      "edit": "Edit policy",
      "policyName": "Policy name",
      "policyType": "{type} policy",
      "accessPolicy": "Access Policy",
      "usagePolicy": "Usage Policy",
      "create": "Create and save",
      "update": "Update"
    }
  },
  "consumer": {
    "contracts": {
      "description": "This is an overview of the data exchange contracts your organization has with other Catena-X members to receive & consume data from them. ",
      "howItWorks": {
        "description": "Here you can find all your subscribed or negotiated data agreements across various dataspaces. You can filter the results for a particular connector or simply search with a contract agreement ID or Asset ID. You can also sort the list to view data agreements based on your requirements. Further, you can select the data offers and export them locally if you wish to.",
        "subtitle": "Want to know more about how data offer negotiation and consumption work?"
      }
    }
  },
  "jobs": {
    "jobs": "Jobs",
    "id": "Id",
    "name": "Name",
    "startedAt": "Started At",
    "status": "Status",
    "duration": "Duration",
    "action": "Actions",
    "jobDetails": "Job Details",
    "reload": "Reload",
    "noDetailsAvailable": "No further details available for this job"
  },
  "search-offer": {
    "pageTitle": "Search data offers",
    "filter": {
      "bpnSearch": "Search by your business partner number or a company name",
      "bpnSearchNote": "Find the data offers by searching with the company name or BPN.",
      "manufacturePartId": "Manufacturer Part Id",
      "manufacturePartIdNote": "Searches for all data offers matching the given part ID.",
      "enter": "Enter {field}",
      "connectorUrl": "Connector URL",
      "connectorUrlNote": "Search for data within specific connectors",
      "subModel": "Submodel",
      "subModelNote": "Fine-tune your search results for your desired use-case",
      "search": "Search"
    },
    "noResult": {
      "title": "No result",
      "description": "Please search to browse available data offers in the network"
    },
    "helpCard": {
      "title": "How does it work?",
      "description": "Discover the data offer catalog of other Catena-X member companies. You can search, select and subscribe to a data offer. You can search for data offers using the company name or with the Manufacturer Part ID or with a combination of two.",
      "subtitle": "Want to know more about how data offer negotiation and consumption work?",
      "documentation": "Documentation"
    },
    "offers": {
      "downloadSelected": "Download Selected",
      "transferSelected": "Transfer Selected",
      "negotiate": "Negotiate",
      "noOffer": "No data offers are available."
    },
    "offer-details": {
      "title": "Confirm contract negotiations",
      "description": "Description",
      "details": "Details",
      "attachedPolicies": "Attached policies",
      "termsCheck": "I agree to the contract terms and I want to start contract negotiation and data exchange.",
      "negotiate": "Negotiate",
      "negotiateAndDownload": "Negotiate and download",
      "cancel": "Cancel",
      "attribute": "Attribute",
      "value": "Value",
      "endpointUrl": "Endpoint URL",
      "organization": "Organization",
      "bpnNumber": "BPN Number",
      "manufacturePartId": "Manufacturer Part Id",
      "noOfAssets": "Number of selected assets",
      "membership": "Membership",
      "dismantlerBrands": "Dismantler Allowed Brands",
      "frameworkAgreement": "Framework Agreement",
      "dismantler": "Dismantler",
      "backToAssets": "Back to assets",
      "noPolicyMatchTitle": "Assets not negotiated or policies not identical",
      "noPolicyMatchText": "You are trying to download some assets which are not negotiated yet. Or some of the assets that you are trying to negotiate, do not have identical policies. Please select correct assets and continue.",
      "negotiateInProgress": "Negotiation and download in progress",
      "negotiateAndDownloadSuccess": "Negotiation and download completed successfully",
      "progress": "Progress",
      "fetchShell": "Fetch shell",
      "exportToCSV": "Export to CSV",
      "fetchJobFailed": "Error while fetching job details",
      "negotiateError": "Error while downloading assets"
    }
  },
  "assetUploadHistory": {
    "title": "Asset Upload History",
    "subtitle": "This history overviews your company's uploaded data to the data space.  All activities are listed here. You can download the data again from this history list.",
    "refresh": "Refresh",
    "processId": "Process Id",
    "providerUrl": "Provider URL",
    "createdOn": "Created on",
    "totalItems": "Total items",
    "successful": "Successful",
    "failed": "Failed",
    "startDate": "Start Date",
    "endDate": "End Date",
    "status": "Status",
    "actions": "Actions",
    "howItWork": {
      "title": "How does it work?",
      "description": "You can find the history of all your uploaded assets across different dataspaces.  You can re-download the files locally or transfer them to an external location. In case of errors, you must retry downloading or renegotiate the data offer.",
      "moreInfo": "Want to know more about download history and what all can you do here?"
    }
  }
}
