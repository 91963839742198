import type { RouteRecordRaw } from "vue-router"
import { routePaths } from "@/router/routePaths"
import { CX_CORE_FEATURES_ENUM, featureCheck } from "@/common/util/featuresUtil"

const applicationRoutes: RouteRecordRaw[] = [
  {
    path: "/applications",
    name: "applications",
    component: () => import("@/layout/AbstractRouteView.vue"),
    meta: {
      isProtected: true
    },
    children: [
      {
        path: routePaths.applications.pcfExchange.path,
        name: routePaths.applications.pcfExchange.pathName,
        component: () =>
          featureCheck(CX_CORE_FEATURES_ENUM.PCF_EXCHANGE)
            ? import("@/modules/applications/pcfExchange/pages/PcfExchange.vue")
            : import("@/modules/error/PageNotFound.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Applications",
              translationKey: "sideMenu.applications",
              routeName: ""
            },
            {
              title: "PCF Exchange",
              translationKey: "sideMenu.pcfExchange"
            }
          ]
        }
      }
    ]
  }
]

export default applicationRoutes
