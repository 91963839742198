{
  "topMenu": {
    "dihTitle": "Data Intelligence Hub",
    "dihSubTitle": "Wert sicher aus Daten extrahieren",
    "logout": "Abmelden"
  },
  "userLogoutAlert": {
    "title": "Möchten Sie sich wirklich abmelden?",
    "back": "Zurück",
    "logout": "Abmelden"
  },
  "sideMenu": {
    "home": "Home",
    "collapse": "Collapse sidebar",
    "help": "Help",
    "prepare": "Prepare",
    "model": "Data model",
    "mapping": "Data mapping",
    "converter": "Data converter",
    "provide": "Provide",
    "publishData": "Provide data",
    "policyPublicationTemplate": "Policy publication template",
    "outgoingDataOffer": "Asset upload history",
    "consume": "Consume",
    "searchDataOffers": "Search data offers",
    "negotiatedDataOffers": "Negotiated data offers",
    "downloadHistory": "Asset download history",
    "govern": "Govern",
    "myPolicies": "My policies",
    "createPolicies": "Create Policies",
    "policyTemplate": "Policy Templates",
    "manageAndConfig": "Configure",
    "connections": "Manage connectors",
    "jobs": "Data exchange logs",
    "monitoring": "Monitor",
    "licences": "Licences",
    "providerContracts": "Subscribed data agreements",
    "consumerContracts": "Negotiated agreements"
  },
  "dropdown": {
    "selectAll": "Alle auswählen",
    "noOption": "Keine Optionen verfügbar",
    "noSuchOption": "Es gibt keine solche Option ..."
  },
  "common": {
    "start": "Start",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "email": "E-Mail-Adresse",
    "phoneNumber": "Telefonnummer",
    "contactPerson": "Ansprechpartner*in",
    "welcome": "Willkommen",
    "street": "Straße",
    "city": "Stadt",
    "country": "Land",
    "postCode": "Postleitzahl",
    "number": "Nummer",
    "next": "Weiter",
    "prev": "Zurück",
    "alreadyAccount": "Verfügen Sie bereits über ein Konto?",
    "login": "Anmelden",
    "loginNow": "Jetzt anmelden",
    "register": "Registrieren",
    "home": "Startseite",
    "submit": "Abschicken",
    "attention": "Bitte beachten",
    "desc": "Beschreibung",
    "website": "Website",
    "contact": "Kontakt",
    "details": "Details",
    "plan": "Plan",
    "listView": "Listenansicht",
    "gridView": "Rasteransicht",
    "firstPage": "Zur ersten Seite",
    "previousPage": "Zur vorherigen Seite",
    "nextPage": "Zur nächsten Seite",
    "lastPage": "Zur letzten Seite",
    "download": "Herunterladen",
    "delete": "Löschen",
    "edit": "Bearbeiten",
    "information": "Informationen",
    "organization": "Organisation",
    "cancel": "Abbrechen",
    "save": "Speichern",
    "upload": "Hochladen",
    "link": "Link",
    "view": "Video",
    "search": "Suchen",
    "searchTerm": "Suchen",
    "selectOption": "Option auswählen",
    "sortBy": "Sortieren nach",
    "dataspace": "Datenraum",
    "select": "Auswählen",
    "date": "Datum",
    "title": "Titel",
    "back": "Back",
    "useCases": "Use cases",
    "done": "Done",
    "enterValue": "Enter a value",
    "selectValue": "Select a value",
    "selectDate": "Select a date",
    "refresh": "Refresh",
    "searchByName": "Search by name"
  },
  "genericError": "Leider ist ein Fehler aufgetreten! Versuchen Sie es bitte erneut.",
  "validation": {
    "fieldRequired": "{field} ist ein Pflichtfeld.",
    "fieldNumeric": "Das Feld {field} darf nur Zahlen enthalten.",
    "fieldAlpha": "Das Feld {field} darf nur Buchstaben, Unter- und Bindestriche enthalten.",
    "fieldAlphanumeric": "Das Feld {field} darf nur Buchstaben, Zahlen, Unter- und Bindestriche enthalten.",
    "fieldNumericDash": "Das Feld {field} darf nur Zahlen und Bindestriche enthalten.",
    "fieldHouseNumber": "Das Feld {field} darf nur Zahlen, Buchstaben und die Zeichen - und / enthalten.",
    "fieldPhoneNumber": "Das Feld {field} darf nur Zahlen und Bindestriche enthalten. Muss mit + oder 00 beginnen.",
    "fieldAlphaDash": "Das Feld {field} darf nur Buchstaben und Bindestriche enthalten.",
    "exceptHTMLTagBrackets": "Das Feld {field} darf keine der folgenden Zeichen enthalten: ^<>",
    "isMaxLength": "überschreitet die maximale Länge von 10 Zeichen.",
    "emailFormat": "E-Mail-Adresse weist ein falsches Format auf",
    "fileSizeError": "Die Datei ist größer als  {size} MB.",
    "csvFileSizeError": "Diese Datei ist zu groß. Sie können Dateien bis zu einer Größe von {size} MB hochladen.",
    "csvFileExtensionError": "Es sind nur Dateien mit der Erweiterung csv zulässig.",
    "csvFileIsEmptyError": "Diese Datei ist leer. Sie müssen eine Datei mit mindestens {size} KB hochladen.",
    "csvFileFormatError": "Die Datei entspricht nicht dem erwarteten Format. Bitte verwenden Sie die bereitgestellte Vorlage und versuchen Sie es erneut.",
    "uploadFails": "Upload fehlgeschlagen",
    "fileExtensionError": "Die Dateierweiterung ist nicht zulässig",
    "fileIsMalwareInfectedError": "Diese Datei scheint Malware zu enthalten. Aus Sicherheitsgründen kann diese Datei nicht hochgeladen werden. Bitte überprüfen Sie Ihre Akte noch einmal",
    "customValidation": "{field} weist ein falsches Format auf.",
    "selectCountryFirst": "Bitte wählen Sie zuerst das Feld \"Ländername\" aus.",
    "validationPostCodeCaseDE": "Das Feld weist ein falsches Format auf. Muss 5 Zahlen enthalten, z. B. 53113.",
    "validationAlphanumericCase": "Das Feld darf nur alphanumerische Zeichen, Bindestriche und Unterstriche enthalten. Der Wert muss mit einem alphanumerischen Zeichen beginnen.",
    "validationNumericDashCase": "Das Feld darf nur Zahlen und Bindestriche enthalten.",
    "url": "Geben Sie eine gültige URL",
    "password": {
      "passwordStrength": "Passwortstärke",
      "weak": "Schwach",
      "average": "Mittel",
      "strong": "Stark",
      "passwordValidationMoreChar": "{count} oder mehr Zeichen",
      "passwordUpperAndLowerChar": "Groß- und Kleinbuchstaben",
      "passwordAtLeastNumber": "Mindestens eine Zahl",
      "passwordAtLeastSpecialChar": "Mindestens ein Sonderzeichen (mit Ausnahme von <>&\"`[])",
      "passwordDiffFromPrev": "Weicht von Ihren vorherigen Passwörtern ab"
    }
  },
  "footer": {
    "tagLine": "Let's power higher performance",
    "imprint": "Impressum",
    "imprintShort": "Impressum",
    "imprintLink": "https://dih.telekom.com/de/impressum",
    "contact": "Kontakt",
    "contactLink": "https://dih.telekom.com/de/kontakt",
    "termsAndConditions": "Nutzungsbedingungen",
    "privacy": "Datenschutzerklärung",
    "privacyShort": "Datenschutz",
    "telekom": "T-Systems International GmbH. Alle Rechte vorbehalten.",
    "telekomShort": "T-Systems International GmbH",
    "note": "Telekom Data Intelligence Hub - Nur für Geschäftskunden",
    "brandedNote": "Powered by Telekom Data Intelligence Hub",
    "help": "Hilfe",
    "cookieSettings": "Cookie-Einstellungen",
    "cookieSettingsShort": "Cookies",
    "life": "Erleben, was verbindet."
  },
  "cookieSettings": {
    "collapsedTitle": "Datenschutzeinstellungen",
    "collapsedText": "Diese Website verwendet Cookies und ähnliche Technologien. Dies sind kleine Textdateien, die auf Ihrem Computer gespeichert und gelesen werden. Indem Sie auf „{0}“ klicken, akzeptieren Sie die Verarbeitung, die Erstellung von individuellen Benutzerprofilen über Websites und Partner hinweg und die Übermittlung Ihrer Daten an Dritte, die Ihre Daten teilweise in Ländern außerhalb der Europäischen Union verarbeiten (DSGVO Art 49). Weiteres finden Sie in Abschnitt 2 des {1}. Die Daten dienen der Analyse, dem Retargeting und der Ausspielung personalisierter Inhalte und Werbung auf Telekom-Seiten und Drittseiten. Weitere Informationen, auch Informationen zur Datenverarbeitung durch Drittanbieter und die Möglichkeit des Widerrufs, finden Sie in den Einstellungen und in unseren Datenschutzhinweisen. Hier können Sie nur mit den erforderlichen Tools {2}.",
    "extendedTitle": "Datenschutzeinstellungen",
    "extendedText": "Um Ihnen ein optimales Webseiten Erlebnis zu bieten, setzen wir Cookies ein. Dazu zählen Cookies für den Betrieb und die Optimierung der Seite als auch für Services, wie die Nutzung des Text- oder Video Chats als auch für an Ihrem online Nutzungsverhalten orientierte Werbung. So kann z.B. erkannt werden, wenn Sie unsere Seiten vom selben Gerät aus wiederholt besuchen. Wir möchten Ihnen die Wahl geben, welche Cookies Sie zulassen:",
    "policyLinkHref": "https://dih.telekom.com/de/datenschutz",
    "policyLinkCookieHref": "https://dih.telekom.com/de/datenschutz/#cookies",
    "policyLinkTextShort": "Hinweise zum Datenschutz",
    "policyLinkText": "Hinweise zum Datenschutz",
    "rejectLinkText": "Weiter",
    "acceptButton": "Alle akzeptieren",
    "saveButton": "Einstellungen speichern",
    "settingsButton": "Einstellungen ändern",
    "showMore": "Mehr anzeigen",
    "showLess": "Weniger anzeigen",
    "categoryTitleEssential": "Erforderliche Cookies",
    "categoryTitleAnalytics": "Analytische Cookies",
    "categoryTitleMarketing": "Marketing-Cookies",
    "categoryTitleThirdParty": "Dienste von anderen Unternehmen (eigenverantwortliche Drittanbieter)",
    "categoryTextEssential": "Diese Cookies sind notwendig, damit Sie durch die Seiten navigieren und wesentliche Funktionen nutzen können. Sie ermöglichen Grundfunktionen, wie die Bestellabwicklung im Online-Shop und den Zugriff auf gesicherte Bereiche der Webseite. Zudem dienen sie der anonymen Auswertung des Nutzerverhaltens, die von uns verwendet werden, um unseren Webauftritt stetig für Sie weiterzuentwickeln.",
    "categoryTextAnalytics": "Diese Cookies helfen uns, das Nutzungsverhalten besser zu verstehen. Analyse-Cookies ermöglichen die Erhebung von Nutzungs- und Erkennungsmöglichkeiten durch Erst- oder Drittanbieter, in so genannten pseudonymen Nutzungsprofilen. Wir benutzen beispielsweise Analyse-Cookies, um die Zahl der individuellen Besucher einer Webseite oder eines Dienstes zu ermitteln oder um andere Statistiken im Hinblick auf den Betrieb unserer Produkte zu erheben, als auch das Nutzerverhalten auf Basis anonymer und pseudonymer Informationen zu analysieren, wie Besucher mit der Webseite interagieren. Ein unmittelbarer Rückschluss auf eine Person ist dabei nicht möglich.",
    "categoryTextMarketing": "Diese Cookies und ähnliche Technologien werden eingesetzt, um Ihnen personalisierte und dadurch relevante Marketing-Inhalte anzeigen zu können. Marketing-Cookies dienen dazu, interessante Werbeinhalte anzuzeigen und die Wirksamkeit unserer Kampagnen einzuschätzen. Dies geschieht nicht nur auf den Websites der Telekom, sondern auch auf denen von anderen Werbepartnern (Drittanbietern). Dies wird auch als Retargeting bezeichnet. Es dient zur Erstellung pseudonymisierter Inhalts- oder Anzeigenprofile, zur Schaltung relevanter Werbung auf anderen Websites sowie dazu, Erkenntnisse über Zielgruppen abzuleiten, die die Anzeigen und Inhalte angesehen haben. Ein unmittelbarer Rückschluss auf Personen ist dabei nicht möglich. Marketing- und Retargeting-Cookies helfen uns, potenziell relevante Werbeinhalte für Sie anzuzeigen. Durch das Unterdrücken von Marketing-Cookies wird Ihnen weiterhin die gleiche Menge an Werbung angezeigt, die jedoch möglicherweise weniger relevant für Sie ist.",
    "categoryTextThirdParty": "Die Websites der Telekom enthalten Links zu Drittanbietern, die ihre Dienste in eigener Verantwortung anbieten. So werden bei Ihrem Besuch der Telekom-Websites mittels Cookies oder ähnlicher Technologien Daten erfasst und an Dritte übermittelt, teilweise zu eigenen Zwecken der Telekom. In welchem Umfang, zu welchen Zwecken und auf Basis welcher Rechtsgrundlage eine Weiterverarbeitung des Drittanbieters erfolgt, entnehmen Sie bitte den Datenschutzhinweisen des Drittanbieters. Informationen zu eigenverantwortlichen Drittanbietern finden Sie im Datenschutzhinweis."
  },
  "messages": {
    "noDataAvailable": "Keine Daten verfügbar",
    "noMatchFound": "Keine Übereinstimmung gefunden",
    "maximumFileSize": "Maximale Dateigröße",
    "suitableFileFormats": "Geeignete Dateiformate",
    "loading": "Ladevorgang…",
    "dragDropText": "oder per Drag & Drop hierher ziehen"
  },
  "pageNotFound": {
    "title": "Hoppla! Anscheinend existiert die gesuchte Seite noch nicht.",
    "subtitle": "Schauen Sie sich in der Zwischenzeit unsere anderen Ressourcen an, die für Sie hilfreich sein könnten!"
  },
  "helpResources": {
    "needHelp": "Brauchen Sie Hilfe?",
    "developerDoc": "Integrator documentation",
    "developerDocDesc": "Get API documentation to get started",
    "example": "Example",
    "tutorials": "Tutorials",
    "exampleDescription": "Find use cases and demos relevant to your Industrial sector",
    "FAQ": "Häufig gestellte Fragen",
    "FAQDescription": "Finden Sie häufigere Antworten von der Abrechnung bis zum Datenschutz",
    "browse": "Durchsuche",
    "demos": "Demos",
    "demosDescription": "Finden Sie Anwendungsfälle und Demos, die für Ihren Industriesektor relevant sind",
    "glossary": "Glossar",
    "glossaryDescription": "Finden Sie die Begriffe und Definitionen im Zusammenhang mit DIH, IDS, Catena-X, GAIA-X und mehr.",
    "contactUs": "This feature is not supported in your current subscription. Please contact us to upgrade."
  },
  "feedbackCard": {
    "title": "Need more help?",
    "contactUs": "Kontaktiere uns"
  },
  "error": {
    "pageNotFound": "Seite nicht gefunden (404)"
  },
  "helpWidget": {
    "askSupport": "Support anfragen",
    "chatboxTitle": "Hilfe chat",
    "chatboxSubtitle": "Telekom Data Intelligence Hub Unterstützung",
    "defaultMessage": {
      "body": "Hallo, willkommen im Support-Kanal des Telekom Data Intelligence Hub. Bitte wählen Sie unten eine Option aus."
    },
    "whatCanWeHelpWith": "Wie können wir ihnen heute helfen?",
    "suggestedAnswers": {
      "knowledgeBase": "Wissensdatenbank, Dokumentation und FAQs",
      "contactSupport": "Kontakt zum Unterstützung",
      "contactSales": "Kontakt zu Vertrieb"
    }
  },
  "provider": {
    "provider": "Provider",
    "contracts": {
      "description": "This is an overview of the data exchange contracts your organization has with other Catena-X members to receive & consume data from them. ",
      "howItWorks": {
        "description": "Here you can find all your subscribed or negotiated data agreements across various dataspaces. You can filter the results for a particular connector or simply search with a contract agreement ID or Asset ID. You can also sort the list to view data agreements based on your requirements. Further, you can select the data offers and export them locally if you wish to.",
        "subtitle": "Want to know more about how data offer negotiation and consumption work?"
      },
      "details": {
        "title": "Contract Details",
        "description": "Description",
        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        "agreementDetails": "Agreement Details",
        "assetDetails": "Asset Details",
        "usagePolicy": "Usage Policy"
      }
    }
  },
  "home": {
    "title": "Connect & Integrate"
  },
  "gettingStarted": {
    "title": "Need help getting started?",
    "openWizard": "Open Wizard",
    "yourDataOffering": "Your data offering",
    "yourAssets": "Your assets",
    "yourPolicies": "Your Policies",
    "preconfiguredCatalogue": "Preconfigured catalogue",
    "contractAgreements": "Contract Agreements",
    "incomingAgreements": "Incoming agreements",
    "outgoingAgreements": "Outgoing agreements",
    "connectors": "Your connectors"
  },
  "yourConnection": {
    "title": "Your connections",
    "viewAllConnection": "View all connectors",
    "help": "Help",
    "documentation": "Get to know your Connect & Integrate",
    "gettingStarted": "Getting started guide",
    "noUseCases": "No use cases available"
  },
  "wizardSelectionPopup": {
    "whatToLike": "What would you like to do today?",
    "prepare": "Prepare",
    "govern": "Govern",
    "provide": "Provide",
    "consume": "Consume",
    "howToProvide": "How would you like to provide data today?",
    "manual": "Manual",
    "automated": "Automated via an API",
    "selectUseCases": "Select your use case(s)",
    "selectSingleUseCase": "Select your use case",
    "selectDigitalTwin": "Select your digital twin type",
    "back": "Back",
    "next": "Next"
  },
  "createOffer": {
    "title": { "standard": "Create data offer", "pcf": "Create PCF data offer" },
    "publish": "Publish",
    "wizard": {
      "provisionAsset": "Provision asset",
      "dataUpload": "Data upload",
      "attachPolicy": "Attach policy",
      "publishDataOffer": "Publish data offer"
    },
    "provisionAsset": {
      "howToSelectSubmodel": "For which submodel would you like to create a data offer?",
      "selectSubmodel": "Select submodel",
      "selectUsecase": "Select a Use Case",
      "selectDigitalTwinType": "Select a digital twin type",
      "whichTypeToSelect": "Which type should I select?",
      "generalInformation": "General Information",
      "name": "Name",
      "id": "ID",
      "description": "Description",
      "contentType": "Content Type",
      "version": "Version",
      "keywords": "Keywords",
      "language": "Language",
      "location": {
        "title": "Location and addressing",
        "text": "Please enter the details of your asset"
      },
      "username": "Username",
      "password": "Password",
      "assetUrl": "Asset URL",
      "assetUrlPlaceHolder": "Please insert your asset URL",
      "clientId": "Client ID",
      "clientSecret": "Client Secret",
      "tokenUrl": "Token Url",
      "tokenUrlPlaceHolder": "Please insert your token URL",
      "provideAssets": "Provide asset",
      "selectConnectorLabel": "Select which connector(s) you would like to share your data with",
      "selectConnectorPlaceholder": "Select a connector",
      "selectRestApiType": "Select REST-API type",
      "selectedAuthMethod": "Authentication method"
    },
    "dataUpload": {
      "selectedDataModel": "Select your data upload",
      "csvUpload": {
        "title": "CSV file upload",
        "uploadFile": "Upload File",
        "uploadFileDescription": "Prepare and upload the file you would like to exchange. You can download the sample file to help you get started with the  format required for Catena-X.",
        "uploadFileDescriptionNote1": "Download the template file from the link below",
        "uploadFileDescriptionNote2": "Fill in the provided file with details of your partners",
        "uploadFileDescriptionNote3": "Upload the file in the provided area",
        "partnerValidationNote": "If you would like to learn more about partner validation, please",
        "clickHere": "click here",
        "downloadTemplate": "Download Template",
        "downloadSampleCsv": "Download Sample csv"
      },
      "jsonUpload": {
        "title": "json upload"
      },
      "tableUpload": {
        "title": "Table upload",
        "add": "Add"
      },
      "fileUpload": {
        "title": "File upload",
        "description": "csv, json"
      },
      "objectStorage": {
        "title": "Object storage",
        "description": "AWS, Azure, OTC or GCP"
      },
      "sftp": {
        "title": "SFTP Server",
        "description": "transfer via SFTP"
      },
      "apiUpload": {
        "title": "REST API",
        "description": "transfer via API",
        "authAndIdentiTitle": "Authentication and identification",
        "authAndIdentiDesc": "Please enter authentication details of your destination system",
        "selectApiType": "Select REST-API type",
        "locationAndAddrTitle": "Location and addressing",
        "locationAndAddrDesc": "Please enter the details of your asset",
        "assetUrl": "Asset URL",
        "username": "Username",
        "password": "Password",
        "clientId": "Client ID",
        "clientSecret": "Client Secret"
      }
    },
    "fileUpload": {
      "title": "Drag and drop your file here",
      "btnText": "Upload"
    },
    "attachPolicy": {
      "title": "Attach a policy to this data offer",
      "createPolicy": "Create a new policy",
      "bpnFieldTitle": "With whom do you want to exchange your data?",
      "howItWork": {
        "title": "How does it work?",
        "description": "We pre-configure the policies based on the Catena-X Use Case framework agreement. Access to your data will be allowed only for the provided Business Partner Numbers.",
        "knowMore": "Want to know more about the policies?",
        "documentation": "Documentation"
      },
      "accessPolicyTableText": "Attach an access policy to this data offer",
      "usagePolicyTableText": " Attach a usage policy to this data offer",
      "non-cx": {
        "howItWork": {
          "title": "How does it work?",
          "description": "Discover the data offer catalog of other Catena-X member companies. You can search, select and subscribe to a data offer. You can search for data offers using the company name or with the Manufacturer Part ID or with a combination of two."
        }
      }
    },
    "publishDataOffer": {
      "dataOfferDetails": "Data offer details",
      "generalInformation": "General Information",
      "attribute": "Attribute",
      "value": "Value",
      "knowMore": "Know more about your data offer",
      "attachedDataAssets": "Attached items",
      "attachedPolicies": "Attached policies",
      "allowPolicyPublish": "I allow policy publication",
      "publish": "Publish",
      "verifiedLegalEntity": "Verified legal entity by",
      "dataOfferCreatedBy": "Data offer created by: ",
      "contactOrgAdmin": "Contact Organization Admin",
      "help": "Help",
      "attachedDataAsset": "Attached data asset",
      "attachedAccessPolicy": "Attached access policy",
      "attachedUsagePolicy": "Attached usage policy",
      "publishedConnectors": "Connectors to which this offer will be published",
      "knowMoreConnector": "Know more about your connectors",
      "knowMorePolicies": "Know more about your policies",
      "knowMoreDataAssets": "Know more about your data assets",
      "knowMoreDataOffers": "Know more about your data offer",
      "publishCheckboxLabel": "I, on behalf of my organization, agree to publish the data offer and assets in the dataspace. I confirm the usage of associated policies to govern the dataspace and associated policies and I will follow the framework of the dataspace for any operation on the offer and assets once published."
    },
    "result": {
      "success": {
        "title": "Successful request.",
        "description": "Asset successfully published. Job id is {id}"
      }
    },
    "success": {
      "title": "Your data offer is now published",
      "description": "Your data offer is now published, click the following for the next steps",
      "createOffer": "Create another data offer",
      "createOfferButton": "Create",
      "goToHome": "Go to home",
      "goToHomeButton": "Go to home",
      "uploadHistory": "Check your upload history"
    }
  },
  "policy": {
    "newPolicy": "New policy",
    "showDeletedPolicies": "Show deleted policies",
    "hideDeletedPolicies": "Show active policies",
    "restoreAsset": "Restore asset",
    "publishDeletion": "Publish deletion",
    "create": {
      "title": "Create new policy",
      "edit": "Edit policy",
      "policyName": "Policy name",
      "policyType": "{type} policy",
      "accessPolicy": "Access Policy",
      "usagePolicy": "Usage Policy",
      "create": "Create and save",
      "update": "Update"
    }
  },
  "consumer": {
    "contracts": {
      "description": "This is an overview of the data exchange contracts your organization has with other Catena-X members to receive & consume data from them. ",
      "howItWorks": {
        "description": "Here you can find all your subscribed or negotiated data agreements across various dataspaces. You can filter the results for a particular connector or simply search with a contract agreement ID or Asset ID. You can also sort the list to view data agreements based on your requirements. Further, you can select the data offers and export them locally if you wish to.",
        "subtitle": "Want to know more about how data offer negotiation and consumption work?"
      }
    }
  },
  "jobs": {
    "jobs": "Jobs",
    "id": "Id",
    "name": "Name",
    "startedAt": "Started At",
    "status": "Status",
    "duration": "Duration",
    "action": "Actions",
    "jobDetails": "Job Details",
    "reload": "Reload",
    "noDetailsAvailable": "No further details available for this job"
  },
  "search-offer": {
    "pageTitle": "Search data offers",
    "filter": {
      "bpnSearch": "Search by your business partner number or a company name",
      "bpnSearchNote": "Find the data offers by searching with the company name or BPN.",
      "manufacturePartId": "Manufacturer Part Id",
      "manufacturePartIdNote": "Searches for all data offers matching the given part ID.",
      "enter": "Enter {field}",
      "connectorUrl": "Connector URL",
      "connectorUrlNote": "Search for data within specific connectors",
      "subModel": "Submodel",
      "subModelNote": "Fine-tune your search results for your desired use-case",
      "search": "Search"
    },
    "noResult": {
      "title": "No result",
      "description": "Please search to browse available data offers in the network"
    },
    "helpCard": {
      "title": "How does it work?",
      "description": "Discover the data offer catalog of other Catena-X member companies. You can search, select and subscribe to a data offer. You can search for data offers using the company name or with the Manufacturer Part ID or with a combination of two.",
      "subtitle": "Want to know more about how data offer negotiation and consumption work?",
      "documentation": "Documentation"
    },
    "offers": {
      "downloadSelected": "Download Selected",
      "transferSelected": "Transfer Selected",
      "negotiate": "Negotiate",
      "noOffer": "No data offers are available."
    },
    "offer-details": {
      "title": "Confirm contract negotiations",
      "description": "Description",
      "details": "Details",
      "attachedPolicies": "Attached policies",
      "termsCheck": "I agree to the contract terms and I want to start contract negotiation and data exchange.",
      "negotiate": "Negotiate",
      "negotiateAndDownload": "Negotiate and download",
      "cancel": "Cancel",
      "attribute": "Attribute",
      "value": "Value",
      "endpointUrl": "Endpoint URL",
      "organization": "Organization",
      "bpnNumber": "BPN Number",
      "manufacturePartId": "Manufacturer Part Id",
      "noOfAssets": "Number of selected assets",
      "membership": "Membership",
      "dismantlerBrands": "Dismantler Allowed Brands",
      "frameworkAgreement": "Framework Agreement",
      "dismantler": "Dismantler",
      "backToAssets": "Back to assets",
      "noPolicyMatchTitle": "Assets not negotiated or policies not identical",
      "noPolicyMatchText": "You are trying to download some assets which are not negotiated yet. Or some of the assets that you are trying to negotiate, do not have identical policies. Please select correct assets and continue.",
      "negotiateInProgress": "Negotiation and download in progress",
      "negotiateAndDownloadSuccess": "Negotiation and download completed successfully",
      "progress": "Progress",
      "fetchShell": "Fetch shell",
      "exportToCSV": "Export to CSV",
      "fetchJobFailed": "Error while fetching job details",
      "negotiateError": "Error while downloading assets"
    }
  },
  "assetUploadHistory": {
    "title": "Asset Upload History",
    "subtitle": "This history overviews your company's uploaded data to the data space.  All activities are listed here. You can download the data again from this history list.",
    "refresh": "Refresh",
    "processId": "Process Id",
    "providerUrl": "Provider URL",
    "createdOn": "Created on",
    "totalItems": "Total items",
    "successful": "Successful",
    "failed": "Failed",
    "startDate": "Start Date",
    "endDate": "End Date",
    "status": "Status",
    "actions": "Actions",
    "howItWork": {
      "title": "How does it work?",
      "description": "You can find the history of all your uploaded assets across different dataspaces.  You can re-download the files locally or transfer them to an external location. In case of errors, you must retry downloading or renegotiate the data offer.",
      "moreInfo": "Want to know more about download history and what all can you do here?"
    }
  }
}
