import type { RouteRecordRaw } from "vue-router"
import { routePaths } from "../routePaths"
import {
  CX_CORE_FEATURES_ENUM,
  DGK_FEATURES_ENUM,
  DIGITAL_TWIN_FEATURES_ENUM,
  featureCheck
} from "@/common/util/featuresUtil"

const consumerRoutes: RouteRecordRaw[] = [
  {
    path: "/consume",
    component: () => import("@/layout/AbstractRouteView.vue"),
    meta: {
      isProtected: true
    },
    children: [
      {
        path: routePaths.consume.offers.path,
        name: routePaths.consume.offers.pathName,
        component: () => import("@/modules/consume/search-offer/pages/SearchCatalog.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Consumer",
              translationKey: "sideMenu.consume"
            },
            {
              title: "Search data offer",
              translationKey: "sideMenu.searchDataOffers",
              routeName: ""
            }
          ]
        }
      },
      {
        path: routePaths.consume.downloadHistory.path,
        name: routePaths.consume.downloadHistory.pathName,
        component: () =>
          featureCheck(DGK_FEATURES_ENUM.DOWNLOAD_HISTORY)
            ? import("@/modules/consume/downloadHistory/pages/DownloadHistory.vue")
            : import("@/modules/error/PageNotFound.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Consumer",
              translationKey: "sideMenu.consume"
            },
            {
              title: "sideMenu.downloadHistory",
              translationKey: "sideMenu.downloadHistory",
              routeName: ""
            }
          ]
        }
      },
      {
        path: routePaths.consume.digitalTwin.path,
        name: routePaths.consume.digitalTwin.pathName,
        component: () =>
          featureCheck(DIGITAL_TWIN_FEATURES_ENUM.BROWSE)
            ? import("@/modules/consume/digital-twin-browser/pages/DigitalTwinBrowserConsume.vue")
            : import("@/modules/error/PageNotFound.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Consumer",
              translationKey: "sideMenu.consume"
            },
            {
              title: "Digital Twin Browser",
              translationKey: "sideMenu.digitalTwin",
              routeName: ""
            }
          ]
        }
      },
      {
        path: routePaths.consume.messagesBrowser.path,
        name: routePaths.consume.messagesBrowser.pathName,
        component: () =>
          featureCheck(CX_CORE_FEATURES_ENUM.MESSAGING)
            ? import("@/modules/consume/messages-browser/pages/MessagesBrowserConsume.vue")
            : import("@/modules/error/PageNotFound.vue"),
        meta: {
          isProtected: true,
          navigation: [
            {
              title: "Consumer",
              translationKey: "sideMenu.consume"
            },
            {
              title: "Messages Browser",
              translationKey: "sideMenu.messagesBrowserInbound",
              routeName: ""
            }
          ]
        }
      }
    ]
  }
]

export default consumerRoutes
