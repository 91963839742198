{
  "topMenu": {
    "dihTitle": "Data Intelligence Hub",
    "dihSubTitle": "Extraer valor de los datos de forma segura",
    "logout": "Cerrar sesión"
  },
  "userLogoutAlert": {
    "title": "¿Seguro que quiere cerrar sesión?",
    "back": "Volver atrás",
    "logout": "Cerrar sesión"
  },
  "sideMenu": {
    "home": "Home",
    "collapse": "Collapse sidebar",
    "help": "Help",
    "prepare": "Prepare",
    "model": "Data model",
    "mapping": "Data mapping",
    "converter": "Data converter",
    "provide": "Provide",
    "publishData": "Provide data",
    "policyPublicationTemplate": "Policy publication template",
    "outgoingDataOffer": "Asset upload history",
    "consume": "Consume",
    "searchDataOffers": "Search data offers",
    "negotiatedDataOffers": "Negotiated data offers",
    "downloadHistory": "Asset download history",
    "govern": "Govern",
    "myPolicies": "My policies",
    "createPolicies": "Create Policies",
    "policyTemplate": "Policy Templates",
    "manageAndConfig": "Configure",
    "connections": "Manage connectors",
    "jobs": "Data exchange logs",
    "monitoring": "Monitor",
    "licences": "Licences",
    "providerContracts": "Subscribed data agreements",
    "consumerContracts": "Negotiated agreements"
  },
  "dropdown": {
    "selectAll": "Seleccionar todo",
    "noOption": "No hay opciones disponibles",
    "noSuchOption": "No existe tal opción..."
  },
  "common": {
    "start": "Iniciar",
    "firstName": "Nombre",
    "lastName": "Apellidos",
    "email": "Dirección de correo electrónico",
    "phoneNumber": "Número de teléfono",
    "contactPerson": "Persona de contacto",
    "welcome": "Bienvenida",
    "street": "Calle",
    "city": "Ciudad",
    "country": "País",
    "postCode": "Código postal",
    "number": "Número",
    "next": "Siguiente",
    "prev": "Volver atrás",
    "alreadyAccount": "¿Ya tiene una cuenta?",
    "login": "Iniciar sesión",
    "loginNow": "Iniciar sesión ahora",
    "register": "Registrarse",
    "home": "Inicio",
    "submit": "Enviar",
    "attention": "Atención",
    "desc": "Descripción",
    "website": "Página web",
    "contact": "Contacto",
    "details": "Detalles",
    "plan": "Plan",
    "listView": "Vista de lista",
    "gridView": "Vista de cuadrícula",
    "firstPage": "Ir a la primera página",
    "previousPage": "Ir a la página anterior",
    "nextPage": "Ir a la página siguiente",
    "lastPage": "Ir a la última página",
    "download": "Descargar",
    "delete": "Eliminar",
    "edit": "Editar",
    "information": "Información",
    "organization": "Organización",
    "cancel": "Cancelar",
    "save": "Guardar",
    "upload": "Cargar",
    "link": "Enlace",
    "view": "Ver",
    "search": "Buscar",
    "searchTerm": "Buscar",
    "selectOption": "Seleccionar una opción",
    "sortBy": "Ordenar por",
    "dataspace": "Espacio de datos",
    "select": "Seleccionar",
    "date": "Fecha",
    "title": "Título",
    "back": "Back",
    "useCases": "Use cases",
    "done": "Done",
    "enterValue": "Enter a value",
    "selectValue": "Select a value",
    "selectDate": "Select a date",
    "refresh": "Refresh",
    "searchByName": "Search by name"
  },
  "genericError": "Se ha producido un error. Por favor, vuelva a intentarlo.",
  "validation": {
    "fieldRequired": "El campo {field} es obligatorio.",
    "fieldNumeric": "El campo sólo puede contener números.",
    "fieldAlpha": "El campo sólo puede contener letras, _ y -.",
    "fieldAlphanumeric": "El campo sólo puede contener letras, números, _ y -.",
    "fieldNumericDash": "El campo sólo puede contener números y -.",
    "fieldHouseNumber": "El campo {field} puede contener solo números, letras y los caracteres - y /.",
    "fieldPhoneNumber": "El campo sólo puede contener números y -. Debe comenzar con + o 00.",
    "fieldAlphaDash": "El campo sólo puede contener letras y -.",
    "exceptHTMLTagBrackets": "El campo no puede contener los siguientes caracteres: ^<>",
    "isMaxLength": "supera la longitud máxima de 10 caracteres.",
    "emailFormat": "La dirección de correo electrónico tiene un formato incorrecto",
    "fileSizeError": "El tamaño del archivo es superior a {size} MB.",
    "csvFileSizeError": "Este archivo es demasiado grande. Puede cargar archivos de hasta {size} MB.",
    "csvFileExtensionError": "Solo se permiten archivos con la extensión csv.",
    "csvFileIsEmptyError": "Este archivo está vacío. Debe cargar al menos {size} KB archivo.",
    "csvFileFormatError": "El archivo no coincide con el formato esperado. Utilice la plantilla proporcionada e inténtelo de nuevo.",
    "uploadFails": "error de carga",
    "fileExtensionError": "La extensión del archivo no es apta",
    "fileIsMalwareInfectedError": "Este archivo parece contener malware. Por razones de seguridad, este archivo no se puede cargar. Por favor, vuelva a revisar su archivo",
    "customValidation": "El campo {field} tiene un formato incorrecto.",
    "selectCountryFirst": "Por favor, seleccione primero el nombre del país",
    "validationPostCodeCaseDE": "El campo tiene un formato incorrecto. Debe contener 5 dígitos; ej., 53113",
    "validationAlphanumericCase": "El campo sólo puede contener caracteres alfanuméricos, guiones y guiones bajos. El valor debe comenzar con un carácter alfanumérico.",
    "validationNumericDashCase": "El campo solo puede contener números y -.",
    "url": "Por favor, introduzca una URL válida",
    "password": {
      "passwordStrength": "Seguridad de la contraseña",
      "weak": "No segura",
      "average": "Intermedia",
      "strong": "Segura",
      "passwordValidationMoreChar": "{count} o más caracteres",
      "passwordUpperAndLowerChar": "Mayúsculas y minúsculas",
      "passwordAtLeastNumber": "Al menos un número",
      "passwordAtLeastSpecialChar": "Al menos un carácter especial (excepto <>&\"`[])",
      "passwordDiffFromPrev": "Difiere de sus contraseñas anteriores"
    }
  },
  "footer": {
    "tagLine": "Potenciemos un mayor rendimiento",
    "imprint": "Nota legal",
    "imprintShort": "Nota legal",
    "imprintLink": "https://dih.telekom.com/en/imprint",
    "contact": "Contacto",
    "contactLink": "https://dih.telekom.com/en/contact",
    "termsAndConditions": "Términos y condiciones",
    "privacy": "Política de privacidad",
    "privacyShort": "Política de priv.",
    "telekom": "T-Systems International GmbH. Todos los derechos reservados",
    "telekomShort": "T-Systems International GmbH",
    "note": "Telekom Data Intelligence Hub â€“ sólo para clientes empresariales",
    "brandedNote": "Con la tecnología de Telekom Data Intelligence Hub",
    "help": "Ayuda",
    "cookieSettings": "Ajustes de cookies",
    "cookieSettingsShort": "Cookies",
    "life": "La vida es para compartir"
  },
  "cookieSettings": {
    "collapsedTitle": "Configuración de privacidad",
    "collapsedText": "Este sitio web utiliza cookies y tecnologías similares. Son pequeños archivos de texto que se almacenan y leen en su computadora. Al hacer clic en '{0}', acepta el procesamiento, la creación de perfiles de usuario individuales en sitios web y socios, y la transferencia de sus datos a terceros, algunos de los cuales procesan sus datos en países fuera de la Unión Europea (RGPD Art. 49). Los detalles se pueden encontrar en la sección 2 de la {1}. Los datos se utilizan para análisis, retargeting y para reproducir contenido publicitario y publicidad personalizados en sitios de Telekom y sitios de terceros. Puede encontrar más información, incluida información sobre el procesamiento de datos por parte de proveedores de terceros y la posibilidad de revocación, en la configuración y en nuestra información de privacidad. Aquí puede {2} solo con las herramientas necesarias.",
    "extendedTitle": "Configuración de privacidad",
    "extendedText": "Para proporcionarle una experiencia óptima en el sitio web, utilizamos cookies. Estas incluyen cookies para el funcionamiento y la optimización del sitio, así como para servicios como chat de texto o video y para publicidad basada en su comportamiento de uso en línea. Esto nos permite, por ejemplo, detectar si visita nuestras páginas repetidamente desde el mismo dispositivo. Nos gustaría darle la opción de qué cookies permite:",
    "policyLinkHref": "https://dih.telekom.com/en/privacy-policy",
    "policyLinkCookieHref": "https://dih.telekom.com/en/privacy-policy",
    "policyLinkTextShort": "información sobre privacidad",
    "policyLinkText": "Información sobre privacidad",
    "rejectLinkText": "continuar",
    "acceptButton": "Aceptar todo",
    "saveButton": "Guardar configuración",
    "settingsButton": "Cambiar configuración",
    "showMore": "Mostrar más",
    "showLess": "Mostrar menos",
    "categoryTitleEssential": "Cookies necesarias",
    "categoryTitleAnalytics": "Cookies analíticas",
    "categoryTitleMarketing": "Cookies de marketing",
    "categoryTitleThirdParty": "Servicios proporcionados por otras empresas (proveedores externos independientes)",
    "categoryTextEssential": "Estas cookies son necesarias para permitirle navegar por los sitios web y utilizar funciones clave. Apoyan funciones básicas, como el procesamiento de pedidos en la tienda en línea y el acceso a áreas seguras de la página web. También sirven para realizar un análisis anónimo de los patrones de usuario, que utilizamos para desarrollar y mejorar continuamente nuestras páginas web para usted.",
    "categoryTextAnalytics": "Estas cookies nos ayudan a mejorar nuestra comprensión del comportamiento del usuario. Las cookies de análisis permiten la recopilación de datos de uso e identificación por parte del proveedor original o proveedores de terceros en perfiles de uso seudónimos. Usamos cookies de análisis, por ejemplo, para determinar el número de visitantes individuales a una página web o un servicio, para recopilar datos estadísticos sobre el rendimiento de nuestros productos y para analizar los patrones de uso y las interacciones de los visitantes en función de información anónima y seudónima. Esta información no se puede rastrear hasta una persona.",
    "categoryTextMarketing": "Estas cookies y tecnologías similares se utilizan para permitir la visualización de contenido de marketing personalizados y, por tanto, relevantes. Las cookies de marketing se utilizan para mostrar contenido publicitario interesante y para medir la eficacia de nuestras campañas. Esto ocurre no sólo en las páginas web de Telekom, sino también en los de otros socios publicitarios (proveedores externos). Esto también se denomina \"retargeting\". Se utiliza para crear perfiles de contenido o anuncios seudónimos, mostrar publicidad relevante en otras páginas web y obtener información sobre los públicos objetivos que han visto los anuncios y contenido. A partir de estos análisis no es posible extraer crelacionar la información con ninguna persona. Las cookies de marketing y las herramientas de retargeting nos ayudan a ofrecerle contenidos publicitarios potencialmente relevantes para usteed. Si desactiva las cookies de marketing, seguirá viendo el mismo número de anuncios, pero éstos pueden ser menos relevantes para usted.",
    "categoryTextThirdParty": "Las páginas web de Telekom incluyen enlaces a proveedores de servicio externos, que prestan sus servicios bajo su propia responsabilidad. Cuando visita páginas web de Telekom, se recopilan datos mediante cookies u otras tecnologías similares y se transmiten a los proveedores externos correspondientes, en parte para fines propios de Telekom. El alcance, finalidad y base jurídica sobre la que se lleva a cabo el tratamiento posterior para los propios fines del tercero pueden consultarse en la política de privacidad de los proveedores externos. La información sobre proveedores externos independientes puede encontrarse en la información sobre privacidad de datos."
  },
  "messages": {
    "noDataAvailable": "No hay datos disponibles",
    "noMatchFound": "No se ha encontrado ninguna coincidencia",
    "maximumFileSize": "Tamaño máximo de archivo",
    "suitableFileFormats": "Formatos de archivo válidos",
    "loading": "Cargando...",
    "dragDropText": "o arrástralo aquí"
  },
  "pageNotFound": {
    "title": "¡Oops! Parece que la página que estás buscando aún no existe.",
    "subtitle": "¡Mientras tanto, echa un vistazo a nuestros otros recursos, que podrían ser útiles para ti!"
  },
  "helpResources": {
    "needHelp": "¿Necesitas ayuda?",
    "developerDoc": "Integrator documentation",
    "developerDocDesc": "Get API documentation to get started",
    "example": "Example",
    "tutorials": "Tutorials",
    "exampleDescription": "Find use cases and demos relevant to your Industrial sector",
    "FAQ": "Preguntas frecuentes",
    "FAQDescription": "Encuentra respuestas comunes desde facturación hasta privacidad",
    "browse": "Navegar",
    "demos": "Demos",
    "demosDescription": "Encuentra casos de uso y demos relevantes para tu sector industrial",
    "glossary": "Glosario",
    "glossaryDescription": "Encuentra los términos y definiciones relacionados con Data Intelligence Hub, IDSA, Catena-X, Gaia-X y más.",
    "contactUs": "This feature is not supported in your current subscription. Please contact us to upgrade."
  },
  "feedbackCard": {
    "giveUsFeedback": "Need more help?",
    "contactUs": "Contáctanos"
  },
  "error": {
    "pageNotFound": "Página no encontrada (404)"
  },
  "helpWidget": {
    "askSupport": "Solicitar asistencia",
    "chatboxTitle": "Chat de ayuda",
    "chatboxSubtitle": "Soporte de Telekom Data Intelligence Hub",
    "defaultMessage": {
      "body": "Hola, bienvenido al canal de soporte de Telekom Data Intelligence Hub. Por favor, selecciona una opción a continuación."
    },
    "whatCanWeHelpWith": "¿En qué podemos ayudarte hoy?",
    "suggestedAnswers": {
      "knowledgeBase": "Base de conocimientos, documentación y preguntas frecuentes",
      "contactSupport": "Contactar soporte",
      "contactSales": "Contactar ventas"
    }
  },
  "provider": {
    "provider": "Provider",
    "contracts": {
      "description": "This is an overview of the data exchange contracts your organization has with other Catena-X members to receive & consume data from them. ",
      "howItWorks": {
        "description": "Here you can find all your subscribed or negotiated data agreements across various dataspaces. You can filter the results for a particular connector or simply search with a contract agreement ID or Asset ID. You can also sort the list to view data agreements based on your requirements. Further, you can select the data offers and export them locally if you wish to.",
        "subtitle": "Want to know more about how data offer negotiation and consumption work?"
      },
      "details": {
        "title": "Contract Details",
        "description": "Description",
        "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        "agreementDetails": "Agreement Details",
        "assetDetails": "Asset Details",
        "usagePolicy": "Usage Policy"
      }
    }
  },
  "home": {
    "title": "Connect & Integrate"
  },
  "gettingStarted": {
    "title": "Need help getting started?",
    "openWizard": "Open Wizard",
    "yourDataOffering": "Your data offering",
    "yourAssets": "Your assets",
    "yourPolicies": "Your Policies",
    "preconfiguredCatalogue": "Preconfigured catalogue",
    "contractAgreements": "Contract Agreements",
    "incomingAgreements": "Incoming agreements",
    "outgoingAgreements": "Outgoing agreements",
    "connectors": "Your connectors"
  },
  "yourConnection": {
    "title": "Your connections",
    "viewAllConnection": "View all connectors",
    "help": "Help",
    "documentation": "Get to know your Connect & Integrate",
    "gettingStarted": "Getting started guide",
    "noUseCases": "No use cases available"
  },
  "wizardSelectionPopup": {
    "whatToLike": "What would you like to do today?",
    "prepare": "Prepare",
    "govern": "Govern",
    "provide": "Provide",
    "consume": "Consume",
    "howToProvide": "How would you like to provide data today?",
    "manual": "Manual",
    "automated": "Automated via an API",
    "selectUseCases": "Select your use case(s)",
    "selectSingleUseCase": "Select your use case",
    "selectDigitalTwin": "Select your digital twin type",
    "back": "Back",
    "next": "Next"
  },
  "createOffer": {
    "title": { "standard": "Create data offer", "pcf": "Create PCF data offer" },
    "publish": "Publish",
    "wizard": {
      "provisionAsset": "Provision asset",
      "dataUpload": "Data upload",
      "attachPolicy": "Attach policy",
      "publishDataOffer": "Publish data offer"
    },
    "provisionAsset": {
      "howToSelectSubmodel": "For which submodel would you like to create a data offer?",
      "selectSubmodel": "Select submodel",
      "selectUsecase": "Select a Use Case",
      "selectDigitalTwinType": "Select a digital twin type",
      "whichTypeToSelect": "Which type should I select?",
      "generalInformation": "General Information",
      "name": "Name",
      "id": "ID",
      "description": "Description",
      "contentType": "Content Type",
      "version": "Version",
      "keywords": "Keywords",
      "language": "Language",
      "location": {
        "title": "Location and addressing",
        "text": "Please enter the details of your asset"
      },
      "username": "Username",
      "password": "Password",
      "assetUrl": "Asset URL",
      "assetUrlPlaceHolder": "Please insert your asset URL",
      "clientId": "Client ID",
      "clientSecret": "Client Secret",
      "tokenUrl": "Token Url",
      "tokenUrlPlaceHolder": "Please insert your token URL",
      "provideAssets": "Provide asset",
      "selectConnectorLabel": "Select which connector(s) you would like to share your data with",
      "selectConnectorPlaceholder": "Select a connector",
      "selectRestApiType": "Select REST-API type",
      "selectedAuthMethod": "Authentication method"
    },
    "dataUpload": {
      "selectedDataModel": "Select your data upload",
      "csvUpload": {
        "title": "CSV file upload",
        "uploadFile": "Upload File",
        "uploadFileDescription": "Prepare and upload the file you would like to exchange. You can download the sample file to help you get started with the  format required for Catena-X.",
        "uploadFileDescriptionNote1": "Download the template file from the link below",
        "uploadFileDescriptionNote2": "Fill in the provided file with details of your partners",
        "uploadFileDescriptionNote3": "Upload the file in the provided area",
        "partnerValidationNote": "If you would like to learn more about partner validation, please",
        "clickHere": "click here",
        "downloadTemplate": "Download Template",
        "downloadSampleCsv": "Download Sample csv"
      },
      "jsonUpload": {
        "title": "json upload"
      },
      "tableUpload": {
        "title": "Table upload",
        "add": "Add"
      },
      "fileUpload": {
        "title": "File upload",
        "description": "csv, json"
      },
      "objectStorage": {
        "title": "Object storage",
        "description": "AWS, Azure, OTC or GCP"
      },
      "sftp": {
        "title": "SFTP Server",
        "description": "transfer via SFTP"
      },
      "apiUpload": {
        "title": "REST API",
        "description": "transfer via API",
        "authAndIdentiTitle": "Authentication and identification",
        "authAndIdentiDesc": "Please enter authentication details of your destination system",
        "selectApiType": "Select REST-API type",
        "locationAndAddrTitle": "Location and addressing",
        "locationAndAddrDesc": "Please enter the details of your asset",
        "assetUrl": "Asset URL",
        "username": "Username",
        "password": "Password",
        "clientId": "Client ID",
        "clientSecret": "Client Secret"
      }
    },
    "fileUpload": {
      "title": "Drag and drop your file here",
      "btnText": "Upload"
    },
    "attachPolicy": {
      "title": "Attach a policy to this data offer",
      "createPolicy": "Create a new policy",
      "bpnFieldTitle": "With whom do you want to exchange your data?",
      "howItWork": {
        "title": "How does it work?",
        "description": "We pre-configure the policies based on the Catena-X Use Case framework agreement. Access to your data will be allowed only for the provided Business Partner Numbers.",
        "knowMore": "Want to know more about the policies?",
        "documentation": "Documentation"
      },
      "accessPolicyTableText": "Attach an access policy to this data offer",
      "usagePolicyTableText": " Attach a usage policy to this data offer",
      "non-cx": {
        "howItWork": {
          "title": "How does it work?",
          "description": "Discover the data offer catalog of other Catena-X member companies. You can search, select and subscribe to a data offer. You can search for data offers using the company name or with the Manufacturer Part ID or with a combination of two."
        }
      }
    },
    "publishDataOffer": {
      "dataOfferDetails": "Data offer details",
      "generalInformation": "General Information",
      "attribute": "Attribute",
      "value": "Value",
      "knowMore": "Know more about your data offer",
      "attachedDataAssets": "Attached items",
      "attachedPolicies": "Attached policies",
      "allowPolicyPublish": "I allow policy publication",
      "publish": "Publish",
      "verifiedLegalEntity": "Verified legal entity by",
      "dataOfferCreatedBy": "Data offer created by: ",
      "contactOrgAdmin": "Contact Organization Admin",
      "help": "Help",
      "attachedDataAsset": "Attached data asset",
      "attachedAccessPolicy": "Attached access policy",
      "attachedUsagePolicy": "Attached usage policy",
      "publishedConnectors": "Connectors to which this offer will be published",
      "knowMoreConnector": "Know more about your connectors",
      "knowMorePolicies": "Know more about your policies",
      "knowMoreDataAssets": "Know more about your data assets",
      "knowMoreDataOffers": "Know more about your data offer",
      "publishCheckboxLabel": "I, on behalf of my organization, agree to publish the data offer and assets in the dataspace. I confirm the usage of associated policies to govern the dataspace and associated policies and I will follow the framework of the dataspace for any operation on the offer and assets once published."
    },
    "result": {
      "success": {
        "title": "Successful request.",
        "description": "Asset successfully published. Job id is {id}"
      }
    },
    "success": {
      "title": "Your data offer is now published",
      "description": "Your data offer is now published, click the following for the next steps",
      "createOffer": "Create another data offer",
      "createOfferButton": "Create",
      "goToHome": "Go to home",
      "goToHomeButton": "Go to home",
      "uploadHistory": "Check your upload history"
    }
  },
  "policy": {
    "newPolicy": "New policy",
    "showDeletedPolicies": "Show deleted policies",
    "hideDeletedPolicies": "Show active policies",
    "restoreAsset": "Restore asset",
    "publishDeletion": "Publish deletion",
    "create": {
      "title": "Create new policy",
      "edit": "Edit policy",
      "policyName": "Policy name",
      "policyType": "{type} policy",
      "accessPolicy": "Access Policy",
      "usagePolicy": "Usage Policy",
      "create": "Create and save",
      "update": "Update"
    }
  },
  "consumer": {
    "contracts": {
      "description": "This is an overview of the data exchange contracts your organization has with other Catena-X members to receive & consume data from them. ",
      "howItWorks": {
        "description": "Here you can find all your subscribed or negotiated data agreements across various dataspaces. You can filter the results for a particular connector or simply search with a contract agreement ID or Asset ID. You can also sort the list to view data agreements based on your requirements. Further, you can select the data offers and export them locally if you wish to.",
        "subtitle": "Want to know more about how data offer negotiation and consumption work?"
      }
    }
  },
  "jobs": {
    "jobs": "Jobs",
    "id": "Id",
    "name": "Name",
    "startedAt": "Started At",
    "status": "Status",
    "duration": "Duration",
    "action": "Actions",
    "jobDetails": "Job Details",
    "reload": "Reload",
    "noDetailsAvailable": "No further details available for this job"
  },
  "search-offer": {
    "pageTitle": "Search data offers",
    "filter": {
      "bpnSearch": "Search by your business partner number or a company name",
      "bpnSearchNote": "Find the data offers by searching with the company name or BPN.",
      "manufacturePartId": "Manufacturer Part Id",
      "manufacturePartIdNote": "Searches for all data offers matching the given part ID.",
      "enter": "Enter {field}",
      "connectorUrl": "Connector URL",
      "connectorUrlNote": "Search for data within specific connectors",
      "subModel": "Submodel",
      "subModelNote": "Fine-tune your search results for your desired use-case",
      "search": "Search"
    },
    "noResult": {
      "title": "No result",
      "description": "Please search to browse available data offers in the network"
    },
    "helpCard": {
      "title": "How does it work?",
      "description": "Discover the data offer catalog of other Catena-X member companies. You can search, select and subscribe to a data offer. You can search for data offers using the company name or with the Manufacturer Part ID or with a combination of two.",
      "subtitle": "Want to know more about how data offer negotiation and consumption work?",
      "documentation": "Documentation"
    },
    "offers": {
      "downloadSelected": "Download Selected",
      "transferSelected": "Transfer Selected",
      "negotiate": "Negotiate",
      "noOffer": "No data offers are available."
    },
    "offer-details": {
      "title": "Confirm contract negotiations",
      "description": "Description",
      "details": "Details",
      "attachedPolicies": "Attached policies",
      "termsCheck": "I agree to the contract terms and I want to start contract negotiation and data exchange.",
      "negotiate": "Negotiate",
      "negotiateAndDownload": "Negotiate and download",
      "cancel": "Cancel",
      "attribute": "Attribute",
      "value": "Value",
      "endpointUrl": "Endpoint URL",
      "organization": "Organization",
      "bpnNumber": "BPN Number",
      "manufacturePartId": "Manufacturer Part Id",
      "noOfAssets": "Number of selected assets",
      "membership": "Membership",
      "dismantlerBrands": "Dismantler Allowed Brands",
      "frameworkAgreement": "Framework Agreement",
      "dismantler": "Dismantler",
      "backToAssets": "Back to assets",
      "noPolicyMatchTitle": "Assets not negotiated or policies not identical",
      "noPolicyMatchText": "You are trying to download some assets which are not negotiated yet. Or some of the assets that you are trying to negotiate, do not have identical policies. Please select correct assets and continue.",
      "negotiateInProgress": "Negotiation and download in progress",
      "negotiateAndDownloadSuccess": "Negotiation and download completed successfully",
      "progress": "Progress",
      "fetchShell": "Fetch shell",
      "exportToCSV": "Export to CSV",
      "fetchJobFailed": "Error while fetching job details",
      "negotiateError": "Error while downloading assets"
    }
  },
  "assetUploadHistory": {
    "title": "Asset Upload History",
    "subtitle": "This history overviews your company's uploaded data to the data space.  All activities are listed here. You can download the data again from this history list.",
    "refresh": "Refresh",
    "processId": "Process Id",
    "providerUrl": "Provider URL",
    "createdOn": "Created on",
    "totalItems": "Total items",
    "successful": "Successful",
    "failed": "Failed",
    "startDate": "Start Date",
    "endDate": "End Date",
    "status": "Status",
    "actions": "Actions",
    "howItWork": {
      "title": "How does it work?",
      "description": "You can find the history of all your uploaded assets across different dataspaces.  You can re-download the files locally or transfer them to an external location. In case of errors, you must retry downloading or renegotiate the data offer.",
      "moreInfo": "Want to know more about download history and what all can you do here?"
    }
  }
}
